<template>
  <div data-app>
    <!-- Banner -->
    <header-two ref="header" v-if="showBanners" :items="banners" />

    <div class="container">
      <v-breadcrumbs style="padding: 0px" :items="items" divider=">"></v-breadcrumbs>
    </div>

    <outstanding-companies
      :outstanding="1"
      v-if="type == 0"
      :country="country"
      :master="master"
      :type="type"
      style="margin: 10px 2%"
    />

    <outstanding-companies2
      :outstanding="1"
      v-if="type == 2 || type == 3 || type == 4"
      :title="''"
      :country="country"
      :type="type"
      style="margin: 10px 2%"
    />

    <div class="row" style="margin: 10px 2%">
      <div class="col-lg-9 col-md-8 col-sm-12">
        <listing-companies
          :country="country"
          :master="master"
          :countryCode="location.countryCode"
          :companyType="type"
          :topCategory="topCategory"
        />
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" style="margin: 0px">
        <top-categories
          v-if="type === 0 || type === 3"
          :companyType="companyType"
          :country_id="country.id"
          @categoryId="topCategorySelected"
        />
        <ads-section-3 :ads="v1Ads" :callToAction="true" />
      </div>
    </div>

    <!-- Seccion inferior 12 columnas -->
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <ads-section-2 :ads="h2Ads" />
      </div>
    </div>
    <Whatsapp />
  </div>
</template>

<script>
  import Header2 from 'Components/globalFrontendComponents/Header2'
  import AdsSection2 from 'Components/globalFrontendComponents/AdsSection2'
  import AdsSection3 from 'Components/globalFrontendComponents/AdsSection3'
  import ListingCompanies from 'Components/globalFrontendComponents/ListingCompanies'
  import OutstandingCompanies from 'Components/globalFrontendComponents/OutstandingCompanies'
  import OutstandingCompanies2 from 'Components/globalFrontendComponents/OutstandingCompanies2'
  import Whatsapp from '@/components/globalFrontendComponents/WhatsApp'
  import TopCategories from '@/components/globalFrontendComponents/CompanyTopCategories.vue'

  export default {
    name: 'CompanyType',
    props: ['companyType', 'country'],
    data() {
      return {
        date: new Date().toISOString(),
        intervalFunction: '',
        headerKey: 1,
        banners: [{ route: '' }],
        location: '',
        showBanners: false,
        master: false,
        h1Ads: [],
        h2Ads: [],
        v1Ads: [],
        v2Ads: [],
        horizontal1Ads: {
          limit: 6,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        horizontal2Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        vertical1Ads: {
          limit: 3,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        topCategory: {},
      }
    },
    watch: {
      country(newValue) {
        this.horizontal2Ads.countryId = newValue.id
        this.vertical1Ads.countryId = newValue.id
        this.getAdvertisements()
      },
      companyType() {
        //console.log(this.$refs.header)
        this.showBanners = false
        this.setBanners()
        this.showBanners = true
      },
    },
    components: {
      ListingCompanies,
      AdsSection2,
      AdsSection3,
      OutstandingCompanies,
      OutstandingCompanies2,
      headerTwo: Header2,
      Whatsapp,
      TopCategories,
    },
    computed: {
      items() {
        if (this.companyType) {
          return [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: this.companyType,
              disabled: true,
              href: '',
            },
          ]
        } else {
          return [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: 'Empresas',
              disabled: true,
              href: '',
            },
          ]
        }
      },
      countryId() {
        return this.country.id
      },
      type() {
        switch (this.companyType) {
          case 'Franquicias':
            return 0
          case 'Asociaciones de Franquicias':
            return 1
          case 'Consultores':
            return 2
          case 'Proveedores':
            return 3
          case 'Oferta Inmobiliaria':
            return 4
          case 'Negocios':
            return 5
          case 'Franquicias Master':
            this.master = true
            return 0
          default:
            return 0
        }
      },

      bannerUrlMobile() {
        switch (this.companyType) {
          case 'Franquicias':
            return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e0eb26fca8c01b11d7c8e65f580bda72.jpg'
          case 'Asociaciones de Franquicias':
            return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/edd40e459a444e7b0520b0d6e0e9d85f.jpg'
          case 'Consultores':
            return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/53956d6e87825f0f16ccb59bac873018.jpg'
          case 'Proveedores':
            return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/f43878f7410cea55059b20b99ceaff64.jpg'
          case 'Oferta Inmobiliaria':
            return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/5bd6b638fe3c99a8951d50b610ae04ed.jpg'
          case 'Negocios':
            return ''
          default:
            return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e0eb26fca8c01b11d7c8e65f580bda72.jpg'
        }
      },
    },
    created() {
      this.setBanners()
      this.getAdvertisements()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      setBanners() {
        this.banners = [{ route: '' }]
        switch (this.companyType) {
          case 'Franquicias':
            this.banners[0].route =
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/37dfb40cabdf76acb9bfd0330d271ba6.jpg'
            break
          case 'Asociaciones de Franquicias':
            this.banners[0].route =
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/8533cfd5263a1c8ba66ba77a7a883670.jpg'
            break
          case 'Consultores':
            this.banners[0].route =
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/8139e9a4b40cdfa00c6f44a9c1037109.jpg'
            this.banners.push({
              route:
                'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/ae9f25e3824f1cefac6b7173711b31da.jpg',
            })
            break
          case 'Proveedores':
            this.banners[0].route =
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/ff4134c6277a9c79412e21940aec3951.jpg'
            this.banners.push({
              route:
                'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/2091117d40ea77b168a48a9bb1a16a33.jpg',
            })
            break
          case 'Franquicias Master':
            this.banners[0].route =
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/37dfb40cabdf76acb9bfd0330d271ba6.jpg'
            break
          default:
            this.banners[0].route =
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/09a540d527eac45a0ffd9e2f2370abd1.jpg'
            this.banners.push({
              route:
                'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/844e7a0f43451bc1d9e2997cd05cb4e1.jpg',
            })
            break
        }
        this.showBanners = true
      },
      adsTimer() {
        this.intervalFunction = setInterval(this.getAdvertisements, 60000)
      },
      getAdvertisements() {
        const h1LimitStr = this.horizontal1Ads
          ? '&horizontal1[limit]=' + this.horizontal1Ads.limit
          : '&horizontal1[limit]=0'
        const h1CtryStr = this.horizontal1Ads
          ? '&horizontal1[country_id]=' + this.horizontal1Ads.countryId
          : '&horizontal1[country_id]=235'
        const h1OutStr = this.horizontal1Ads
          ? '&horizontal1[outstanding]=' + this.horizontal1Ads.outstanding
          : '&horizontal1[outstanding]=0'
        const h2LimitStr = this.horizontal2Ads
          ? '&horizontal2[limit]=' + this.horizontal2Ads.limit
          : '&horizontal2[limit]=0'
        const h2CtryStr = this.horizontal2Ads
          ? '&horizontal2[country_id]=' + this.horizontal2Ads.countryId
          : '&horizontal2[country_id]=235'
        const h2OutStr = this.horizontal2Ads
          ? '&horizontal2[outstanding]=' + this.horizontal2Ads.outstanding
          : '&horizontal2[outstanding]=0'
        const v1LimitStr = this.vertical1Ads
          ? '&vertical1[limit]=' + this.vertical1Ads.limit
          : '&vertical1[limit]=0'
        const v1CtryStr = this.vertical1Ads
          ? '&vertical1[country_id]=' + this.vertical1Ads.countryId
          : '&vertical1[country_id]=235'
        const v1OutStr = this.vertical1Ads
          ? '&vertical1[outstanding]=' + this.vertical1Ads.outstanding
          : '&vertical1[outstanding]=0'
        const v2LimitStr = this.vertical2Ads
          ? '&vertical2[limit]=' + this.vertical2Ads.limit
          : '&vertical2[limit]=0'
        const v2CtryStr = this.vertical2Ads
          ? '&vertical2[country_id]=' + this.vertical2Ads.countryId
          : '&vertical2[country_id]=235'
        const v2OutStr = this.vertical2Ads
          ? '&vertical2[outstanding]=' + this.vertical2Ads.outstanding
          : '&vertical2[outstanding]=0'

        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisement-management/get-advertisements?' +
              h1LimitStr +
              h1CtryStr +
              h1OutStr +
              h2LimitStr +
              h2CtryStr +
              h2OutStr +
              v1LimitStr +
              v1CtryStr +
              v1OutStr +
              v2LimitStr +
              v2CtryStr +
              v2OutStr
          )
          .then((response) => {
            this.setBanners()
            this.h1Ads = response.data.horizontal1
            if (this.type != 1) {
              this.h1Ads.forEach((element) => {
                element.image.link = element.redirection
                element.image.title = element.name
                this.banners.push(element.image)
              })
            }
            this.h2Ads = response.data.horizontal2
            this.v1Ads = response.data.vertical1
            this.v2Ads = response.data.vertical2
            this.showBanners = true
            this.headerKey++
          })
          .catch((error) => {})
      },
      topCategorySelected(item) {
        this.topCategory = item
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
    metaInfo() {
      return {
        title: `${this.companyType} - XPOFranchise`,
        meta: [
          {
            name: 'description',
            content:
              'Te invitamos a conocer el directorio que recopila la más amplia información de franquicias y afines, siendo la guía mas completa, actualizada y moderna de Iberoamérica.',
          },
          {
            property: 'og:title',
            content: `${this.companyType} - XPOFranchise`,
          },
          {
            property: 'og:description',
            content:
              'Te invitamos a conocer el directorio que recopila la más amplia información de franquicias y afines, siendo la guía mas completa, actualizada y moderna de Iberoamérica.',
          },
          {
            property: 'og:image',
            content:
              'https://xpofranchise.comhttps://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/d3700893b85a68ba4f6877ce9102962b.svg',
          },
          {
            property: 'og:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            property: 'og:updated_time',
            content: this.date,
          },
          {
            name: 'twitter:title',
            content: `${this.companyType} - XPOFranchise`,
          },
          {
            name: 'twitter:description',
            content:
              'Te invitamos a conocer el directorio que recopila la más amplia información de franquicias y afines, siendo la guía mas completa, actualizada y moderna de Iberoamérica.',
          },
          {
            name: 'twitter:image',
            content:
              'https://xpofranchise.comhttps://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/d3700893b85a68ba4f6877ce9102962b.svg',
          },
          {
            name: 'twitter:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            name: 'og:updated_time',
            content: this.date,
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  // Responsive Variables
  // @import '/src/assets/scss/custom-styles/media-query.scss';

  $extra-large: 'only screen and (min-width: 1200px)';
  $large: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $medium: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $small: 'only screen and (min-width: 576px) and (max-width: 767px)';
  $extra-small: 'only screen and (max-width: 575px)';
  .icon-btn {
    min-height: auto;
    float: left;
  }

  .icon-btn button {
    float: left;
    margin: 10px;
  }

  .btn-gradient {
    width: auto;
    position: relative;
    display: inline-block;
    left: -20px;
    background: rgba(0, 0, 0, 0.15);
    border-top-right-radius: 60px;
    padding: 8px 24px 8px 16px;
    box-shadow: 2px 0px 0px 0px rgba(78, 72, 72, 0.4);
  }

  .radius-style button {
    border-radius: 30px;
  }

  .radius-btn {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  .btn-gradient i {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message {
    font-size: 23px;
    width: 70%;
    margin: auto;
  }

  .nav-button {
    flex: 0 0 15%;
    max-width: 15%;
    border: 1px solid #2b4d68;
    margin: 0px auto;
    /*border-radius: 10px;*/
    background-color: rgb(0 58 86 / 70%);
    box-shadow: 5px 5px 5px 5px rgb(72 73 121 / 15%);
  }

  .custom-buttom {
    flex: 0 0 20%;
    max-width: 20%;
    margin: 0px auto;
    padding: 0px auto 5px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border-radius: 10px;*/
    background: #003a56;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
  }

  .custom-buttom-one {
    flex: 0 0 20%;
  }

  .custom-buttom:hover {
    cursor: pointer;
    background-color: #141d38 !important;
    transform: scale(1.1);
  }
</style>
