<template>
  <div class="row">
    <div class="col-lg-12 text-center" v-if="ads.length > 0">
      <span style="font-size: 11px">Publicidad</span>
      <div class="" v-for="ad in ads" :key="ad.id">
        <a
          :href="ad.redirection"
          target="_blank"
          rel="noopener"
          @click="setClickStats(ad.id)"
          class="btn"
        >
          <img :src="ad.image.route" style="max-width: 300px" :alt="ad.name"
        /></a>
      </div>
      <div class="row" v-if="callToAction ? callToAction : false" style="margin: 0px">
        <div class="col-lg-12 col-sm-12 text-center" style="padding: 0.625rem 1.25rem">
          <div class="subs-widget bg-gradient-info p-4">
            <img
              src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/d3700893b85a68ba4f6877ce9102962b.svg"
              width="150"
              class="mb-4 img-fluid"
              alt=""
            />
            <h4 class="text-white">¿Necesitas ayuda?</h4>

            <p class="text-white mb-0">
              Solicita información acerca de nuestros servicios y nos pondremos en
              contacto contigo
            </p>
            <a
              class="btn btn-primary mt-2"
              style="color: #fff"
              href="/#contacto"
              type="button"
              >Solicítalo aquí</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 text-center" v-else>
      <span style="font-size: 11px">Publicidad</span>
      <v-skeleton-loader
        :boilerplate="boilerplateActive"
        style="padding: 0rem 1.25rem 0.625rem 1.25rem"
        class="mx-auto"
        type="image"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['ads', 'callToAction'],
    data() {
      return {
        boilerplateActive: true,
      }
    },
    watch: {
      ads(newValue, oldValue) {
        oldValue.forEach((ad) => {
          this.setTimeStats(ad.id)
        })
        newValue.forEach((ad) => {
          this.setPrintStats(ad.id)
        })
      },
    },
    methods: {
      async setClickStats(id) {
        axios.patch(
          process.env.VUE_APP_API_DIRECTORY + 'advertisement-management/add-stats/' + id,
          {
            stats: [{ print_count: 0, print_time: 0, click_count: 1 }],
          }
        )
      },
      setTimeStats(id) {
        axios.patch(
          process.env.VUE_APP_API_DIRECTORY + 'advertisement-management/add-stats/' + id,
          {
            stats: [{ print_count: 0, print_time: 60, click_count: 0 }],
          }
        )
      },
      async setPrintStats(id) {
        axios.patch(
          process.env.VUE_APP_API_DIRECTORY + 'advertisement-management/add-stats/' + id,
          {
            stats: [{ print_count: 1, print_time: 0, click_count: 0 }],
          }
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  img {
    width: 100%;
    height: 100%;
  }
  .container {
    margin: 10px auto 50px auto;
    padding: 0px;
  }
  .bg-gradient-info {
    background: radial-gradient(
      circle,
      rgba(0, 155, 221, 1) 35%,
      rgba(0, 58, 86, 1) 100%
    );
  }
</style>
