<template data-app>
  <slick
    ref="carousel"
    :options="slickOptions"
    class="listing-slider mfp-gallery-container margin-bottom-0"
    style="height: calc(100vw * 0.28125)"
  >
    <template v-for="(item, index) in items">
      <div :key="index">
        <router-link
          v-if="redirect"
          :to="item.redirect"
          :style="{ 'background-image': 'url(' + item.route + ')' }"
          class="item mfp-gallery"
          :title="item.title ? item.title : 'banner'"
          :alt="item.title ? item.title : 'banner'"
        ></router-link>
        <a
          v-else
          :style="{ 'background-image': 'url(' + item.route + ')' }"
          :target="item.link ? '_blank' : ''"
          rel="noopener"
          :alt="item.title ? item.title : 'banner'"
          :href="item.link ? item.link : '#'"
          class="item mfp-gallery"
          :title="item.title ? item.title : 'banner'"
        ></a>
      </div>
    </template>
  </slick>
</template>

<script>
  import Slick from 'vue-slick'
  export default {
    name: 'Header2',
    props: ['items', 'redirect'],
    components: {
      Slick,
    },
    data() {
      return {
        show: true,
      }
    },
    watch: {
      items(newValue, oldValue) {
        this.reInit()
      },
    },
    methods: {
      reInit() {
        this.$refs.carousel.destroy()
        this.$nextTick(() => {
          this.$refs.carousel.create()
          this.$refs.carousel.goTo(0, true)
        })
      },
    },

    computed: {
      slickOptions() {
        return {
          dots: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 10000,
          arrows: false,
          speed: 2000,
          fade: true,
          cssEase: 'linear',
          centerMode: true,
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 6,
          responsive: [
            {
              breakpoint: 1367,
              settings: {
                arrows: false,
                centerPadding: '0',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                arrows: false,
                centerPadding: '0',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 767,
              settings: {
                arrows: false,
                centerPadding: '0',
                slidesToShow: 1,
              },
            },
          ],
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .listing-slider .slick-slide .item {
    background-size: cover;
    height: calc(100vw * 0.28125);
  }
  .slick-slider {
    height: calc(100vw * 0.28125);
  }
</style>
