<template>
  <v-card v-if="visible" style="margin: 9px 0" tile flat>
    <v-list dense>
      <v-subheader style="justify-content: center; font-size: 1rem; font-weight: bold"
        >Top 10 de Categorias</v-subheader
      >
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          justify="space-between"
          v-for="(item, i) in items"
          :key="i"
          inactive
          @click="$emit('selectedCategory', item)"
        >
          <span style="width: 100%"> {{ item.name }}</span>
          <v-chip style="justify-content: center; max-width: 50px; width: 50px">{{
            item.companies_count
          }}</v-chip>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: 'TopCategories',
    data() {
      return {
        // loading: false,
        // loadingText: "",
        selectedItem: '',
        visible: null,
        items: [],
        grid: {},
      }
    },
    props: { companyType: String, country_id: Number },
    created() {
      this.getCategories()
      this.vueGrid()
    },
    watch: {
      companyType() {
        this.getCategories()
      },
      country_id() {
        this.getCategories()
      },
    },
    computed: {
      type() {
        switch (this.companyType) {
          case 'Franquicias':
            return 0
          case 'Asociaciones de Franquicias':
            return 1
          case 'Consultores':
            return 2
          case 'Proveedores':
            return 3
          case 'Oferta Inmobiliaria':
            return 4
          case 'Franquicias Master':
            this.master = true
            return 0
          default:
            return 0
        }
      },
    },
    methods: {
      async getCategories() {
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'categories?&type=' +
              this.type +
              '&country_id=' +
              this.country_id
          )
          .then((response) => {
            if (response.data.data.length >= 10) {
              this.visible = true
              this.items = response.data.data
                .sort((a, b) => (a.companies_count > b.companies_count ? -1 : 1))
                .slice(0, 10)
            } else {
              this.visible = false
            }
          })
      },
      vueGrid() {
        this.grid = this.$vuetify.breakpoint
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-chip .v-chip__content {
    justify-content: center;
  }
</style>
