import { render, staticRenderFns } from "./CompanyTopCategories.vue?vue&type=template&id=17ceadd4&scoped=true&"
import script from "./CompanyTopCategories.vue?vue&type=script&lang=js&"
export * from "./CompanyTopCategories.vue?vue&type=script&lang=js&"
import style0 from "./CompanyTopCategories.vue?vue&type=style&index=0&id=17ceadd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ceadd4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCard,VChip,VList,VListItem,VListItemGroup,VSubheader})
