<template>
  <div class="container" style="margin: 0">
    <div class="row" v-if="title && title != ''">
      <div class="col-12">
        <h3 class="text-center">{{ title }}</h3>
      </div>
    </div>
    <div class="row" v-if="companies.length == 0">
      <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5" v-for="index in 5" :key="index">
        <v-skeleton-loader
          :boilerplate="boilerplateActive"
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </div>
    </div>
    <slick
      :options="slickOptions"
      v-else
      class="category-carousel"
      style="height: 250px; pointer-events: all"
    >
      <template v-for="company in companies" class="item" style="height: 250px">
        <div class="listing-item-container" :key="company.id">
          <div class="listing-item text-center">
            <div class="listing-item-content" style="padding: 5%">
              <div
                class="box"
                style="
                  height: 55%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <router-link :to="'/empresa/' + company.link">
                  <img
                    v-if="!company.logo"
                    src="/static/images/generico.png"
                    style="max-width: 100%"
                    :alt="company.name"
                  />
                  <img
                    v-else
                    :src="company.logo.route"
                    :alt="company.name"
                    style="max-width: 100%"
                  />
                </router-link>
              </div>
              <router-link :to="'/empresa/' + company.link">
                <div style="height: 45%">
                  <h5 class="mb-0" style="margin-top: 5%">
                    {{ company.name }}
                  </h5>
                  <span
                    style="margin-left: 5px"
                    v-for="(category, index) in company.categories.slice(0, 2)"
                    :key="index"
                    class="badge badge-pill badge-primary text-uppercase badge-cat"
                    >{{ category.name }}
                  </span>
                </div>
              </router-link>
              <router-link :to="'/empresa/' + company.link"
                ><span
                  title="Franquicia destacada"
                  class="round-pill like-banner d-block bg-primary action-span"
                  ><i class="fa fa-star-o"></i></span
              ></router-link>
            </div>
          </div>
        </div>
      </template>

      <!--<div
				class="card col-xs-5 col-sm-5 col-md-5 col-lg-5"
				style="justify-content: center"
				v-for="company in companies"
				:key="company.id"
			>
				<div class="listing-item-container">
					<div class="listing-item text-center">
						<div style="height: 100%">
							<div class="listing-item-content" style="padding: 10%">
								<div style="height: 80%; margin: auto 0%">
									<div style="margin-buttom: 5px">
										<router-link to="/">
											<img
												v-if="!company.logo"
												src="/static/images/generico.png"
												style="width: 90%; padding: 0px"
												:alt="company.name"
											/>
											<img
												v-else
												:src="company.logo.route"
												:alt="company.name"
												style="width: 90%; padding: 0px"
											/>
										</router-link>
									</div>
									<a
										><h5 class="mb-0" style="margin-top: 0%">{{ company.name }}</h5></a
									>
									<span
										style="margin-left: 5px"
										v-for="(category, index) in company.categories"
										:key="index"
										class="badge badge-pill badge-primary text-uppercase badge-cat"
										>{{ category.name }}</span
									>
								</div>
							</div>
							<router-link :to="'/empresa/' + company.link"
								><span
									title="Franquicia destacada"
									class="round-pill like-banner d-block bg-primary action-span"
									><i class="fa fa-star-o"></i></span
							></router-link>
						</div>
					</div>
				</div>
			</div>-->
    </slick>
  </div>
</template>
<script>
  import Slick from 'vue-slick'
  export default {
    props: ['country', 'type', 'outstanding', 'title'],
    components: {
      Slick,
    },
    data() {
      return {
        intervalFunction: '',
        companies: [],
        loading: true,
        boilerplateActive: false,
      }
    },
    watch: {
      country(newValue, oldValue) {
        this.getCompanies()
      },
      type(newValue, oldValue) {
        this.getCompanies()
        this.adsTimer()
      },
    },
    created() {
      this.getCompanies()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      adsTimer() {
        this.intervalFunction = setInterval(this.getCompanies, 60000)
      },
      async getCompanies() {
        let countryStr = ''
        let limitStr = '&limit=5'
        let outstangingStr = ''
        if (this.country && this.country.id) {
          countryStr = '&country_id=' + this.country.id
        }
        if (this.outstanding && this.outstanding == 1) {
          outstangingStr = '&outstanding=1'
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-list?random=1&active=1&type=' +
              this.type +
              countryStr +
              outstangingStr +
              limitStr
          )
          .then((response) => {
            this.companies = response.data
            if (this.companies.length == 0) {
              this.boilerplateActive = true
            }
            this.loading = false
          })
      },
    },
    computed: {
      slickOptions() {
        return {
          centerMode: true,
          centerPadding: '0%',
          slidesToShow: 5,
          dots: true,
          arrows: false,
          responsive: [
            {
              breakpoint: 1441,
              settings: {
                centerPadding: '0px',
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                centerPadding: '0px',
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                centerPadding: '0px',
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerPadding: '0px',
                slidesToShow: 2,
              },
            },
          ],
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
  }
</script>
<style lang="css" scoped>
  .action-span {
    transform: rotate(360deg);
    transition-property: rotation;
    transition-timing-function: linear;
    transition-duration: 3s;
    animation: rotation 1s infinite linear;
  }
  .listing-item-container {
    height: 260px;
    padding: 10px;
  }
  .listing-item {
    height: 100%;
    box-shadow: 0px 4px 10px 2px rgb(0 0 0 / 20%);
    border-radius: 5px;
  }
  .listing-item-content {
    height: inherit;
  }
  .like-banner {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: contents;
    height: inherit;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: inherit;
  }
  .fullwidth-slick-carousel.category-carousel .slick-slide {
    height: inherit;
  }
  .fullwidth-slick-carousel.category-carousel .slick-slide {
    height: 240px;
  }
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .fullwidth-slick-carousel .slick-slide {
    pointer-events: all !important;
  }
</style>
